<script setup lang="ts">
import { defineEmits, ref, onMounted, watch, defineProps, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import countries from '@/shared/countries.json'
import { useSubscriptionStore } from '@/store/subscription/subscriptionStore'
import createHttpClient from '@/api/httpClient'
import MessageService from '@/components/feedback/message/messageService'
import { useUserTvaStore } from '@/store/tva/userTvaStore'
import ChangeSubscription from '@/components/ChangeSubscription.vue'
import { SubscriptionData } from '@/shared/subscription'
import { useStripe } from '@/shared/payment'
import { useDisplay, useTheme } from 'vuetify'
import { InformationData } from '@/api/account/information.d'
import { useUserStore } from '@/store/user/userStore'

const subscriptionStore = useSubscriptionStore()
const httpClient = createHttpClient()
const globalErrors = ref()
const information = ref<InformationData>()
const userStore = useUserStore()

const { t } = useI18n()
const emit = defineEmits(['update:company', 'update:close'])

const requiredRules = [v => !!v || `${t('dashboard.subscription.required')}`]
const loading = ref(false)
const userTva = useUserTvaStore()
const useAlreadySave = ref(true)

const form = ref({
  newPlan: '',
  newCountry: '',
  newCity: '',
  newPostalCode: '',
  newAddress: '',
  newCompany: '',
  newTVANumber: '',
})

const {
  stripe,
  stripeError,
  stripeCard,
  stripeCardExpiry,
  stripeCardCvc,
  initStripe,
} = useStripe(useTheme())

const fieldFocus = field => {
  setTimeout(() => {
    field.focus()
  }, 10)
}

const isValidForm = ref(false)
const isInitializing = ref(false)
const choice = ref('')

const getValidType = computed(() => {
  return subscriptionStore.getSubscriptionType()
})

const onSubmit = async () => {
  if (disabledWhenChanging.value) return false

  globalErrors.value = ''
  if (!isValidForm.value) return false

  const new_plan =
    choice.value === 'annual'
      ? 'annually'
      : choice.value === 'lifetime'
        ? 'life'
        : choice.value

  form.value = { ...form.value, newPlan: new_plan }

  try {
    loading.value = true
    information.value = {
      ...information.value,
      countryCompany: form.value.newCountry,
      tvaNumberCompany: form.value.newTVANumber,
    }

    const response = await subscriptionStore.updateInformation(
      information.value,
    )

    if (!response) {
      loading.value = false
      return false
    }

    if (displayPaymentDetail.value) {
      if (useAlreadySave.value) {
        if (subscriptionStore.isCardExpired) {
          globalErrors.value = t('expired-card')
          loading.value = false
          return false
        }
        const res = await subscriptionStore.changeSubscriptionPlan(form.value)
        if (res) {
          if (res.data.status == 'requires_action') {
            stripe.value
              .confirmCardPayment(res.data.clientSecret, {
                payment_method: res.data.paymentMethodId,
              })
              .then(async () => {
                await httpClient
                  .post('subscription/confirm-active-plan', {
                    plan: form.value.newPlan,
                    paymentIntentId: res.data.paymentIntentId,
                  })
                  .then(confirm_response => {
                    MessageService.success(
                      t('dashboard.subscription.change_with_success'),
                    )

                    loading.value = false
                    subscriptionStore.toggleSubscription()
                    subscriptionStore.fetchSubscriptionStatus()
                    subscriptionStore.fetchPayments()
                    userStore.refreshToken()
                  })
                  .catch(error => {
                    if (error?.response) {
                      if (error?.response?.data?.message) {
                        let error_msg = error.response.data.message
                        if (error.response?.status == 422) {
                          MessageService.error(error_msg)
                        }
                      }
                    } else {
                      MessageService.error(t('error_occured'))
                    }
                  })

                loading.value = false
                // subscriptionStore.toggleSubscription()
                // subscriptionStore.fetchPayments()
                // userStore.refreshToken()
                // subscriptionStore.fetchSubscriptionStatus()
              })
          } else {
            subscriptionStore.toggleSubscription()
            subscriptionStore.fetchPayments()
            userStore.refreshToken()
            subscriptionStore.fetchSubscriptionStatus()
            MessageService.success(
              t('dashboard.subscription.change_with_success'),
            )
          }
          return false
        }
        loading.value = false
      } else {
        await create_pm(form.value)
      }
    } else {
      const res = await subscriptionStore.changeSubscriptionPlan(form.value)

      if (res) {
        subscriptionStore.toggleSubscription()
        subscriptionStore.fetchPayments()
        userStore.refreshToken()
        subscriptionStore.fetchSubscriptionStatus()
        MessageService.success(t('dashboard.subscription.change_with_success'))
      }
      loading.value = false
    }
  } catch (error) {
    console.log('catch error')
  }
}

const create_pm = async (payload: any) => {
  if (stripeError.value.length) {
    loading.value = false
    globalErrors.value = stripeError.value
    return false
  }

  await stripe.value
    .createPaymentMethod({
      type: 'card',
      card: stripeCard.value,
      billing_details: {
        name: userStore.user.firstName,
      },
    })
    .then(result => {
      if (result.error) {
        globalErrors.value = result.error.message
        loading.value = false
        return null
      } else {
        const paymentMethodId = result.paymentMethod.id
        subscriptionStore
          .updateUserCard(paymentMethodId)
          .then(clientSecret => {
            if (choice.value === 'lifetime') {
              subscriptionStore
                .changeSubscriptionPlan(payload)
                .then(response => {
                  if (response) {
                    if (response.data.status == 'requires_action') {
                      stripe.value
                        .confirmCardPayment(response.data.clientSecret, {
                          payment_method: {
                            card: stripeCard.value,
                            billing_details: {
                              email: userStore.user.email,
                            },
                          },
                        })
                        .then(async result => {
                          if (result.error) {
                            globalErrors.value = result.error.message
                            loading.value = false
                            // subscriptionStore.fetchSubscriptionStatus()
                            return false
                          } else {
                            await httpClient
                              .post('subscription/confirm-active-plan', {
                                plan: payload.newPlan,
                                paymentIntentId: response.data.paymentIntentId,
                              })
                              .then(confirm_response => {
                                loading.value = false
                                MessageService.success(
                                  t(
                                    'dashboard.subscription.change_with_success',
                                  ),
                                )
                                subscriptionStore.toggleSubscription()
                                subscriptionStore.fetchSubscriptionStatus()
                                subscriptionStore.fetchPayments()
                                userStore.refreshToken()
                              })
                          }
                        })
                    } else {
                      MessageService.success(
                        t('dashboard.subscription.change_with_success'),
                      )
                      loading.value = false
                      subscriptionStore.fetchPayments()
                      subscriptionStore.fetchSubscriptionStatus()
                      userStore.refreshToken()
                      subscriptionStore.toggleSubscription()
                    }
                  } else {
                    loading.value = false
                  }
                })
                .catch(() => {
                  loading.value = false
                })
            } else {
              // 3D secure
              stripe.value
                .confirmCardSetup(clientSecret, {
                  payment_method: result.paymentMethod.id,
                })
                .then(result => {
                  if (result.error) {
                    globalErrors.value = result.error.message
                    loading.value = false
                  } else {
                    subscriptionStore
                      .changeSubscriptionPlan(payload)
                      .then(async response => {
                        if (response) {
                          if (response.data.status == 'requires_action') {
                            stripe.value
                              .confirmCardPayment(response.data.clientSecret, {
                                payment_method: paymentMethodId,
                              })
                              .then(async result => {
                                // MessageService.success(
                                //   t('dashboard.subscription.change_with_success'),
                                // )
                                if (result.error) {
                                  globalErrors.value = result.error.message
                                  loading.value = false
                                  // subscriptionStore.fetchSubscriptionStatus()
                                } else {
                                  await httpClient
                                    .post('subscription/confirm-active-plan', {
                                      plan: payload.newPlan,
                                      paymentIntentId:
                                        response.data.paymentIntentId,
                                    })
                                    .then(confirm_response => {
                                      MessageService.success(
                                        t(
                                          'dashboard.subscription.change_with_success',
                                        ),
                                      )
                                      loading.value = false
                                      subscriptionStore.toggleSubscription()
                                      subscriptionStore.fetchSubscriptionStatus()
                                      subscriptionStore.fetchPayments()
                                      userStore.refreshToken()
                                    })
                                }
                              })
                          } else {
                            MessageService.success(
                              t('dashboard.subscription.change_with_success'),
                            )
                            loading.value = false
                            subscriptionStore.toggleSubscription()
                            subscriptionStore.fetchSubscriptionStatus()
                            subscriptionStore.fetchPayments()
                            userStore.refreshToken()
                          }
                        } else if (!response) {
                          MessageService.error('')
                          loading.value = false
                          subscriptionStore.fetchSubscriptionStatus()
                          subscriptionStore.fetchPayments()
                          userStore.refreshToken()
                        }
                      })
                      .catch(() => {
                        loading.value = false
                      })
                  }
                })
            }
          })
          .catch(error => {
            loading.value = false
            globalErrors.value = error.response.data.message
          })
      }
    })
    .catch(err => {
      loading.value = false
    })
}

const tva = ref(0)

watch(
  () => subscriptionStore.changeSubscription,
  newVal => {
    isInitializing.value = true
    if (
      subscriptionStore.cardData &&
      subscriptionStore.cardData.lastFour &&
      !subscriptionStore.isCardExpired
    ) {
      useAlreadySave.value = true
    } else {
      useAlreadySave.value = false
    }
    choice.value = getValidType.value

    initStripe()

    setTimeout(() => {
      isInitializing.value = false
    }, 0)
  },
  { immediate: true },
)

watch(
  () => useAlreadySave.value,
  () => {
    if (!useAlreadySave.value) {
      initStripe()
    }
  },
)

watch(
  () => choice.value,
  () => {
    if (choice.value === 'lifetime') {
      initStripe()
    }
  },
)

watch(
  () => form.value.newCountry,
  async () => {
    if (isInitializing.value) return false
    if (form.value.newTVANumber) {
      tva.value = 0
    } else {
      tva.value = await subscriptionStore.tvaByCountry(form.value.newCountry)
    }
    await subscriptionStore.asignPlanChoise(
      SubscriptionData[choice.value][0],
      tva.value,
    )
  },
)

watch(
  () => form.value.newTVANumber,
  async () => {
    if (isInitializing.value) return false
    if (form.value.newTVANumber) {
      tva.value = 0
    } else {
      tva.value = await subscriptionStore.tvaByCountry(form.value.newCountry)
    }
    await subscriptionStore.asignPlanChoise(
      SubscriptionData[choice.value][0],
      tva.value,
    )
  },
  { flush: 'post' },
)
const the_price = ref(null)
const the_tva = ref(null)

const toggleSubscription = (v: 'monthly' | 'annual' | 'lifetime') => {
  if (subscriptionStore.getSubscriptionType() === 'lifetime') return false
  choice.value = v
}

onMounted(() => {
  if (!subscriptionStore.userInformation) return
  information.value = subscriptionStore.userInformation

  form.value.newCompany = information.value.nameCompany
  form.value.newCountry = information.value.countryCompany
  form.value.newAddress = information.value.addressCompany
  form.value.newTVANumber = information.value.tvaNumberCompany
  form.value.newCity = information.value.cityCompany
  form.value.newPostalCode = information.value.postalCodeCompany

  tva.value = subscriptionStore.subscriptionStatus['tva']

  the_price.value = subscriptionStore.subscriptionStatus['actualPrice']
  the_tva.value = subscriptionStore.subscriptionStatus['tva']

  subscriptionStore.asignPlanChoise(
    SubscriptionData[choice.value][0],
    tva.value,
  )
})

const displayCurrentSubscriptionBtn = computed(() => {
  const currentSubscription = getValidType.value

  if (currentSubscription === subscriptionStore.planChoise.id) {
    return true
  }

  return false
})

const loadTva = computed(() => {
  return userTva.loadTva
})

watch(
  () => [choice.value],
  async () => {
    await subscriptionStore.asignPlanChoise(
      SubscriptionData[choice.value][0],
      tva.value,
    )
  },
  { deep: true },
)

const closeChangeSubscription = async () => {
  if (loading.value) return false
  subscriptionStore.toggleSubscription()
  await subscriptionStore.fetchSubscriptionStatus()
}

const formatCardNumber = computed(() => {
  return `**** **** **** ${subscriptionStore.cardData.lastFour}`
})

const formatExpired = computed(() => {
  return `${String(subscriptionStore.cardData.expiryMonth).padStart(2, '0')} / ${subscriptionStore.cardData.expiryYear}`
})

const displayPaymentDetail = computed(() => {
  if (subscriptionStore.trialRemainingDays > 0) {
    // en période d'essai
    if (choice.value === 'lifetime') {
      return true
    } else {
      return false
    }
  } else {
    return true
  }
})

const disabledWhenChanging = computed(() => {
  if (
    subscriptionStore.subscriptionStatus['status'] === 'canceled' ||
    subscriptionStore.subscriptionStatus['status'] === 'incomplete_expired' ||
    subscriptionStore.subscriptionStatus['status'] === 'unpaid'
  ) {
    return false
  }

  if (
    subscriptionStore.subscriptionStatus['status'] !== 'active' &&
    subscriptionStore.subscriptionStatus['type'] === 'monthly' &&
    subscriptionStore.subscriptionStatus['monthsAlreadyPaid'] <= 40
  ) {
    if (subscriptionStore.getSubscriptionType() === choice.value) {
      return true
    }
  }

  if (subscriptionStore.subscriptionStatus['status'] === 'active') {
    // subscription en cour active
    if (subscriptionStore.getSubscriptionType() === choice.value) {
      return true
    }
  }

  return false
})
</script>

<template>
  <v-dialog
    :model-value="subscriptionStore.changeSubscription"
    persistent
    max-width="700"
    transition="dialog-top-transition"
  >
    <v-card color="white" rounded="xl">
      <v-card-title
        class="py-3 d-flex justify-center w-full align-center bg-surface-lighten-1"
      >
        <span class="font-weight-bold title">{{
          t('dashboard.subscription.change_subscription_title')
        }}</span>
        <img
          style="position: absolute; right: 10px"
          class="cursor-pointer mr-2"
          @click="closeChangeSubscription"
          src="@/assets/_close.svg"
        />
      </v-card-title>

      <v-card-text>
        <v-form @submit.prevent="onSubmit" v-model="isValidForm">
          <div class="card-body mb-3">
            <div
              class="mt-2 w-100 mx-auto cursor-pointer d-flex justify-space-around align-center d-inline-flex px-sm-2 px-2 py-1 rounded-lg ga-sm-5 mb-5"
              style="background-color: #f5f5f5; font-size: 13px"
            >
              <div
                class="px-sm-5 px-2"
                @click="toggleSubscription('monthly')"
                :class="{
                  'bg-secondary py-1 rounded-lg animate-btn':
                    choice == 'monthly',
                }"
              >
                {{ t('practitionerPresentation.pricingSection.mensual') }}
              </div>
              <div
                class="px-sm-5 px-2"
                @click="toggleSubscription('annual')"
                :class="{
                  'bg-secondary py-1 rounded-lg animate-btn':
                    choice == 'annual',
                }"
              >
                <span
                  >Annuel
                  <span
                    :class="
                      choice == 'annual'
                        ? 'text-white'
                        : 'text-secondary font-weight-bold'
                    "
                    >-50%</span
                  ></span
                >
              </div>
              <div
                class="px-sm-5 px-2"
                @click="toggleSubscription('lifetime')"
                :class="{
                  'bg-secondary px-5 py-1 rounded-lg animate-btn':
                    choice == 'lifetime',
                }"
              >
                {{ t('practitionerPresentation.pricingSection.life') }}
              </div>
            </div>

            <div class="d-flex justify-center">
              <!-- <p v-if="loadTva" class="italic text-blue">
                {{ $t('dashboard.subscription.loadsubscription') }}...
              </p> -->

              <ChangeSubscription
                :currentFormule="displayCurrentSubscriptionBtn"
                color="#F5F5F5"
              />
            </div>
          </div>

          <v-container class="pa-xs-0 px-md-10">
            <div class="text-center billing-info-title">
              {{ t('dashboard.subscription.billing_info') }}
            </div>

            <template v-if="displayPaymentDetail">
              <div v-if="subscriptionStore.cardData.lastFour">
                <v-radio-group
                  class="d-flex justify-center text-center"
                  inline
                  hide-details
                  v-model="useAlreadySave"
                >
                  <v-radio
                    color="secondary"
                    :readonly="loading"
                    :label="$t('use_already_save')"
                    :value="true"
                    class="text-rigth"
                  ></v-radio>

                  <v-radio
                    color="secondary"
                    :readonly="loading"
                    :label="$t('new_card')"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </div>
            </template>

            <template v-if="displayPaymentDetail">
              <div
                v-if="
                  subscriptionStore.cardData &&
                  subscriptionStore.cardData.lastFour &&
                  useAlreadySave
                "
                class="d-sm-flex ga-sm-2"
              >
                <div class="w-sm-50 mb-sm-0 mb-2">
                  <label>{{ t('register.card_number') }}</label>
                  <p class="text-disabled">{{ formatCardNumber }}</p>
                </div>

                <div class="w-sm-50">
                  <label>{{ t('expired') }}</label>
                  <p class="text-disabled">{{ formatExpired }}</p>
                </div>
              </div>
            </template>

            <template v-if="displayPaymentDetail">
              <div class="d-flex flex-column ga-sm-2" v-if="!useAlreadySave">
                <label>{{ t('register.card_number') }}</label>
                <v-card color="white" elevation="0" border>
                  <div class="pa-3 py-3">
                    <card-input
                      @update:focused="fieldFocus(stripeCard)"
                      :error-messages="stripeError"
                      :readonly="loading"
                    >
                      <div id="card-element">
                        <!-- Elements will create input elements here -->
                      </div>
                    </card-input>
                  </div>
                  <v-divider />
                  <v-card-text border class="pa-0">
                    <div class="d-sm-flex">
                      <div class="w-sm-50 border-custom pa-3">
                        <card-input
                          :readonly="loading"
                          placeholder="MM / AA"
                          @update:focused="fieldFocus(stripeCardExpiry)"
                          hide-details
                        >
                          <div id="card-expiry">
                            <!-- Elements will create input elements here -->
                          </div>
                        </card-input>
                      </div>
                      <div class="w-sm-50 pa-3">
                        <card-input
                          :readonly="loading"
                          placeholder="***"
                          @update:focused="fieldFocus(stripeCardCvc)"
                          hide-details
                        >
                          <div id="card-cvc" style="width: 100%">
                            <!-- Elements will create input elements here -->
                          </div>
                        </card-input>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </template>

            <div class="d-sm-flex align-center ga-2">
              <div class="w-sm-50 w-100">
                <label>Pays</label>
                <v-autocomplete
                  rounded="lg"
                  density="compact"
                  v-model="form.newCountry"
                  :items="countries"
                  :rules="requiredRules"
                  clearable
                />
              </div>

              <div class="py-0 w-sm-50 w-100">
                <label>% de TVA</label>

                <v-text-field
                  readonly
                  rounded="lg"
                  v-model="tva"
                  class="text-disabled"
                  density="compact"
                />
              </div>
            </div>

            <div>
              <div class="d-flex align-center">
                <label>{{ t('dashboard.subscription.tva_number') }}</label>
                <v-tooltip :text="$t('register.tva_infobulle')">
                  <template v-slot:activator="{ props }">
                    <v-icon
                      size="x-small"
                      class="cursor-pointer"
                      color="#757575"
                      v-bind="props"
                      >mdi-information</v-icon
                    >
                  </template>
                </v-tooltip>
              </div>
              <v-text-field
                rounded="lg"
                density="compact"
                v-model="form.newTVANumber"
                hide-details
                class="mb-2"
              ></v-text-field>
            </div>

            <div class="">
              <label>{{ t('register.billing_address') }}</label>
              <v-card color="white" elevation="0" border>
                <v-card-text border class="pa-0">
                  <div class="pa-3">
                    <input
                      class="pa-0 no-focus w-100"
                      :placeholder="$t('register.billing_address_placeholder')"
                      v-model="form.newAddress"
                    />
                  </div>
                  <v-divider />
                  <div class="d-sm-flex address">
                    <div class="w-sm-50 border-custom pa-3">
                      <input
                        class="pa-0 no-focus w-100"
                        :placeholder="$t('register.postal_code')"
                        v-model="form.newPostalCode"
                      />
                    </div>
                    <div class="w-sm-50 pa-3">
                      <input
                        class="pa-0 no-focus w-100"
                        :placeholder="$t('register.city')"
                        v-model="form.newCity"
                      />
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>

            <div class="text-red text-caption text-center mt-2">
              {{ globalErrors }}
            </div>

            <div class="d-flex justify-center">
              <v-btn
                :disabled="disabledWhenChanging"
                :loading="loading"
                type="submit"
                color="white"
                class="bg-secondary px-5 mt-5 w-sm-75 mx-auto"
                >{{
                  t('dashboard.subscription.change_subscription_btn')
                }}</v-btn
              >
            </div>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.title {
  color: #007058;
  font-weight: 600px;
  font-size: 20px;
  line-height: 32.2px;
}

.card-body {
  width: 389px;
  margin: auto;
}

.no-focus:focus {
  outline: none;
  box-shadow: none;
}
.border-custom {
  border-right: 1px solid #ccc;
}

.card-body {
  width: 416px;
  margin: auto;
}

.billing-info-title {
  color: #616161;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.current_subscription_btn {
  font-size: 13px;
}

label {
  font-size: 14px;
}

@media (max-width: 599px) {
  .card-body {
    width: 100%;
    margin: auto;
  }

  .border-custom {
    border-right: none;
    border-bottom: 1px solid #ccc;
  }
}
</style>
