import { defineStore } from 'pinia'
import { Ref, ref, computed } from 'vue'
import { SubscriptionStatusResponse } from '@/api/subscription/subscription.d'
import { ApiResponse } from '@/api/api'
import { getSubscription } from '@/api/subscription/subscription'
import i18n from '@/plugins/i18n'
import { AxiosResponse } from 'axios'
import { ICardInfo } from './CardInfo.d'
import createHttpClient from '@/api/httpClient'
import { InformationData } from '@/api/account/information.d'
import MessageService from '@/components/feedback/message/messageService'
import { useUserStore } from '../user/userStore'
import { useUserTvaStore } from '../tva/userTvaStore'
// @ts-ignore
import { Plan } from '@/shared/subscription'
import { UpdateCardResponse } from '@/api/card/card.d'
import { useDashboardStatus } from '@/store/dashboard-status/dashboardStatus'
import dayjs from 'dayjs'
import { SubscriptionData } from '@/shared/subscription'

const { t } = i18n.global
const httpClient = createHttpClient()

export const useSubscriptionStore = defineStore('subscription', () => {
  const subscriptionStatus: Ref<SubscriptionStatusResponse> = ref()
  const displayModal = ref(false)
  const displayPresubModal = ref(false)
  const cardData = ref<ICardInfo>()
  const userInformation = ref<InformationData>()
  const userStore = useUserStore()
  const userTva = useUserTvaStore()
  const activationLoad = ref(false)
  const loadSubscription = ref(false)
  const loadInformation = ref(false)
  const planChoise = ref<Plan>()
  const loadCard = ref(false)
  const paymentListLoading = ref(false)
  const paymentList = ref([])
  const paymentTotalItems = ref(0)
  const changeSubscription = ref(false)
  const changing_plan = ref(false)
  const alreadyPay = ref(false)
  const dashboardStatus = useDashboardStatus()
  const startSubscription = ref(false)
  const startSubscriptionLoading = ref(false)
  const subscriptionEnd = ref(null)
  const cancelSubscription = ref(false)
  const cancelLoading = ref(false)

  subscriptionEnd.value = userStore.user.subscriptionEnd

  // fetch subscription status
  const fetchSubscriptionStatus = async () => {
    if (loadSubscription.value) return false
    loadSubscription.value = true
    try {
      const res: ApiResponse<SubscriptionStatusResponse> = await httpClient.get(
        '/subscription/status',
      )
      subscriptionStatus.value = res.data

      const type = getSubscriptionType()
      await asignPlanChoise(SubscriptionData[type][0], res.data.tva)
      if (res.data.secondaryStatus == 'active_but_cancellation_planned') {
        subscriptionEnd.value = res.data.subscriptionEndDate
      }
    } catch (err) {
      console.log('err', err)
      MessageService.error('')
    } finally {
      loadSubscription.value = false
    }
  }

  const toggleModal = () => {
    displayModal.value = !displayModal.value
  }

  const togglePreModal = () => {
    displayPresubModal.value = !displayPresubModal.value
  }

  const fetchCardInfo = async () => {
    if (loadCard.value) return false
    loadCard.value = true
    try {
      const res: ApiResponse<ICardInfo> = await httpClient.get(
        '/payment/payment-method',
      )
      cardData.value = res.data
    } catch {
    } finally {
      loadCard.value = false
    }
  }

  const updateCardLocally = (payload: ICardInfo) => {
    cardData.value = payload
  }

  // mise à jour la carte bleu de l'utilisateur
  const updateUserCard = async (paymentMethodId: string) => {
    if (!paymentMethodId) return false
    const response = await httpClient.put<UpdateCardResponse>(
      '/payment/create-payment-method',
      { paymentMethodId },
    )
    if (response.data.status != 'error') {
      return response.data.clientSecret
    } else {
      return {
        status: false,
        message: response.data.message,
      }
    }
  }

  // suppression de la methode de paiement
  const deletePaymentMethod = async (paymentMethodId: string) => {
    try {
      await httpClient.post('payment/delete-payment-method', {
        paymentMethodId,
      })
      await fetchCardInfo()
    } catch (error) {
      console.log('error ===>>>', error)
    }
  }

  const fetchInformation = async () => {
    if (loadInformation.value) return
    loadInformation.value = true
    try {
      const res: ApiResponse<InformationData> = await httpClient.get(
        '/account/informations',
      )
      userInformation.value = res.data
    } catch {
    } finally {
      loadInformation.value = false
    }
  }

  const updateInformation = async (payload: InformationData) => {
    userInformation.value = { ...payload }
    try {
      const data = {
        countryCompany: userInformation.value.countryCompany,
        tvaNumberCompany: userInformation.value.tvaNumberCompany,
      }
      await httpClient.post('/account/billing-informations', data)
      await fetchInformation()
      await userTva.getUserTva()
      return true
    } catch (error) {
      if (error.response?.status === 422) {
        console.log('response ===>>>', error.response?.data?.message)
        MessageService.error(t(`${error.response?.data?.message}`))
      } else {
        MessageService.error(t('update_info_error'))
      }
      return false
    }
  }

  const fetchInfoData = async () => {
    await fetchInformation()
    await userTva.getUserTva()
  }

  const tvaByCountry = async countryCompany => {
    if (!countryCompany) return 0
    const response: ApiResponse<number> = await httpClient.get(
      `/utils/tva?country=${countryCompany}`,
      { headers: { 'Content-Type': 'application/json' } },
    )

    return response.data
  }

  const activeAccount = async () => {
    if (activationLoad.value) return false
    activationLoad.value = true
    try {
      const response = await httpClient.post('/account/active')

      // MessageService.success(t('dashboard.home.status.success'))

      // refresh token
      userStore.refreshToken()

      // fetch subscription status
      await fetchSubscriptionStatus()

      // fetch payment
      await fetchPayments()

      if (response && response.data) {
        return response
      } else {
        return false
      }
    } catch (error) {
      if (error.response?.status === 422) {
        MessageService.error(t('error_occured'))
      } else {
        MessageService.error(t('dashboard.home.status.error'))
      }
      return false
    } finally {
      activationLoad.value = false
      dashboardStatus.fetchDashboardStatus()
      fetchSubscriptionStatus()
    }
  }

  const asignPlanChoise = (payload: Plan, tva_percent: number) => {
    payload = { ...payload, tva_percent: tva_percent }
    planChoise.value = payload
  }

  const finalPrice = (price: number) => {
    planChoise.value = { ...planChoise.value, finalPrice: price }
    return true
  }

  const fetchPayments = async (page = 1, itemsPerPage = 10) => {
    console.info('fetch payment')
    alreadyPay.value = false
    if (paymentListLoading.value) return false
    const offset = (page - 1) * itemsPerPage

    try {
      const res = await httpClient.get(
        `/payment/mines?limit=${itemsPerPage}&offset=${offset}`,
      )
      paymentList.value = res.data.payments
      paymentTotalItems.value = res.data.count

      let i = 0

      if (
        subscriptionStatus.value &&
        subscriptionStatus.value['type'] === 'life'
      ) {
        while (i < paymentList.value.length) {
          if (paymentList.value[i].status === 'succeeded') {
            alreadyPay.value = true
            break
          }
          i++
        }
      }

      // Abonnement à vie: paiement manuel en base de donnée
      if (
        subscriptionStatus.value &&
        subscriptionStatus.value['type'] === 'life' &&
        subscriptionStatus.value['monthsAlreadyPaid'] === 40
      ) {
        alreadyPay.value = true
      }
    } catch (error) {
      MessageService.error('')
      console.log('error', error)
    } finally {
      paymentListLoading.value = false
    }
  }

  const alreadyMakePayment = computed(() => {
    let i = 0
    let res = false

    while (i < paymentList.value.length) {
      if (paymentList.value[i].status === 'succeeded') {
        res = true
        break
      }
      i++
    }

    return res
  })

  const toggleSubscription = () => {
    changeSubscription.value = !changeSubscription.value
  }

  const toggleStartSubscription = () => {
    startSubscription.value = !startSubscription.value
  }

  const changeSubscriptionPlan = async (payload: any) => {
    if (changing_plan.value) return false
    try {
      changing_plan.value = true
      const res = await httpClient.post('subscription/change-plan', payload)

      console.log(res)
      console.log(res.data)
      if (res && res.data) {
        return res
      } else {
        console.log('here')
        return false
      }
    } catch (error) {
      let error_msg = error.response.data.message
      if (error.response?.status == 422) {
        MessageService.error(error_msg)
      } else {
        MessageService.error(t('dashboard.home.status.error'))
      }
      return false
    } finally {
      changing_plan.value = false
      dashboardStatus.fetchDashboardStatus()
      fetchCardInfo()
    }
  }

  // nombre de jour restant pour la période d'éssai
  const trialRemainingDays = computed(() => {
    const today = dayjs()
    const target = dayjs(
      subscriptionStatus.value && subscriptionStatus.value['trial'],
    )
    const day = target.diff(today, 'day')
    if (day > 0) {
      return day
    }
    return 0
  })

  // nombre de jours restant apres annulation de l'abonnement
  const remainingDaysAfterCanceled = computed(() => {
    const today = dayjs()
    const target = dayjs(
      subscriptionStatus.value &&
        subscriptionStatus.value['subscriptionEndDate'],
    )
    const day = target.diff(today, 'day')
    if (day > 0) {
      return day
    }
    return 0
  })

  const hasDiscount = computed(() => {
    // commenté le 19-10-2024 car on utilise désormais discountMonthsRemaining pour vérifier si le user bénéficie du discount
    // is trialing period
    // let hasSuccessPayment = false
    // if (trialRemainingDays.value && trialRemainingDays.value > 0) {
    //   return true
    // } else {
    //   // n'est pas en période d'éssai et a un préabonnement >= à 6
    //   if (
    //     subscriptionStatus.value &&
    //     (subscriptionStatus.value['monthsAlreadyPaid'] as number) >= 6
    //   ) {
    //     return false
    //   }
    //   // n'est plus en période d'essai
    //   // beneficie encore des mois de réduction

    //   // has at least one successful payment
    //   let i = 0
    //   while (i < paymentList.value.length) {
    //     if (paymentList.value[i].status === 'succeeded') {
    //       hasSuccessPayment = true
    //       break
    //     }
    //     i++
    //   }

    //   // est ce qu'il a déja un payment actif?
    //   if (hasSuccessPayment) {
    //     if (
    //       subscriptionStatus.value['discountMonthsRemaining'] &&
    //       (subscriptionStatus.value['discountMonthsRemaining'] as number) > 0
    //     ) {
    //       return true
    //     } else {
    //       return false
    //     }
    //   } else {
    //     return true // continue de bénéficier des prix de depart
    //   }
    // }
    // return (subscriptionStatus.value.discountMonthsRemaining as number) > 0
    return (subscriptionStatus.value.discountPercentage as number) > 0
  })

  const getSubscriptionType = () => {
    if (subscriptionStatus.value['type'] == 'annually') {
      return 'annual'
    } else if (subscriptionStatus.value['type'] == 'life') {
      return 'lifetime'
    } else {
      return subscriptionStatus.value['type']
    }
  }

  const getFinalPrice = computed(() => {
    let price = null
    let discount = 0
    let tva = planChoise.value.tva_percent

    if (hasDiscount.value) {
      discount = subscriptionStatus.value['discountPercentage']
    }

    price = userTva.applyTva(planChoise.value.old_price, tva, discount)

    // if (trialRemainingDays.value > 0) {
    //   price = userTva.applyTva(planChoise.value.old_price, tva, discount)
    // } else {
    //   // période d'éssai terminé
    //   // beneficie encore des mois de réduction
    //   // if (
    //   //   subscriptionStatus.value &&
    //   //   (subscriptionStatus.value['discountPercentage'] as number) > 0
    //   // ) {
    //   //   price = userTva.applyTva(planChoise.value.old_price, tva, discount)
    //   // } else {
    //   //   discount = 0
    //   //   price = userTva.applyTva(planChoise.value.old_price, tva, discount)
    //   // }
    //   price = userTva.applyTva(planChoise.value.old_price, tva, discount)
    // }
    return price
  })

  // plan description message
  const subscriptionMessage = computed(() => {
    let msg = ''
    let tva = planChoise.value.tva_percent

    const price = getFinalPrice.value

    if (planChoise.value.id == 'lifetime') {
      msg = `TVA = ${tva}% soit ${price}€ ${tva ? 'TTC' : 'HT'} facturé en une seule fois.`
    } else {
      msg = `TVA = ${tva}% soit ${price}€ ${tva ? 'TTC' : 'HT'} facturé ${planChoise.value.id === 'annual' ? 'annuellement' : 'mensuellement'}.`
    }
    return msg
  })

  // next bill amount
  const nextBillAmount = computed(() => {
    // commenté le 19-10-2024 car on utilise désormais actualPrice pour le montant du prochain paiement

    // let discount = null
    // let tva = planChoise.value.tva_percent

    // if (hasDiscount.value) {
    //   discount = planChoise.value.discount
    // }

    // return userTva.applyTva(planChoise.value.old_price, tva, discount)
    return subscriptionStatus.value['actualPrice']
  })

  // strar my subscription now
  const startMySubscription = async (billing: string) => {
    if (startSubscriptionLoading.value) return false
    startSubscriptionLoading.value = true
    try {
      const response = await httpClient.post('/account/active-now', { billing })

      // refresh token
      userStore.refreshToken()

      // fetch subscription status
      await fetchSubscriptionStatus()

      // fetch payment
      await fetchPayments()

      if (response && response.data) {
        return response
      } else {
        return false
      }
    } catch (error) {
      let error_msg = error.response.data.message
      if (error.response?.status == 422) {
        MessageService.error(error_msg)
      } else {
        MessageService.error(t('dashboard.home.status.error'))
      }
      return false
    } finally {
      startSubscriptionLoading.value = false
      dashboardStatus.fetchDashboardStatus()
      fetchSubscriptionStatus()
    }
  }

  const toggleCancelSubscription = () => {
    cancelSubscription.value = !cancelSubscription.value
  }

  const cancelTrialSubscription = async (raison: string) => {
    if (cancelLoading.value) return
    cancelLoading.value = true
    try {
      await httpClient.post('/subscription/cancel', { raison })

      // refresh token
      userStore.refreshToken()

      // fetch subscription status
      await fetchSubscriptionStatus()

      // fetch payment
      await fetchPayments()

      MessageService.success(t('cancel_with_success'))
      toggleCancelSubscription()
    } catch (err) {
      MessageService.error('')
    } finally {
      cancelLoading.value = false
    }
  }

  const isCardExpired = computed(() => {
    const expiryYear = parseInt(cardData.value.expiryYear)
    const expiryMonth = parseInt(cardData.value.expiryMonth)

    const today = dayjs()

    const currentYear = parseInt(today.format('YYYY'))
    const currentMonth = parseInt(today.format('MM'))
    return (
      expiryYear < currentYear ||
      (expiryYear === currentYear && expiryMonth < currentMonth)
    )
  })

  return {
    fetchSubscriptionStatus,
    subscriptionStatus,
    displayModal,
    toggleModal,
    fetchCardInfo,
    cardData,
    updateUserCard,
    updateCardLocally,
    fetchInformation,
    userInformation,
    updateInformation,
    tvaByCountry,
    activeAccount,
    activationLoad,
    togglePreModal,
    displayPresubModal,
    planChoise,
    asignPlanChoise,
    finalPrice,
    deletePaymentMethod,
    loadSubscription,
    loadInformation,
    loadCard,
    fetchInfoData,
    paymentListLoading,
    paymentTotalItems,
    paymentList,
    fetchPayments,
    changeSubscription,
    toggleSubscription,
    changeSubscriptionPlan,
    changing_plan,
    alreadyPay,
    trialRemainingDays,
    hasDiscount,
    getSubscriptionType,
    subscriptionMessage,
    nextBillAmount,
    startSubscription,
    toggleStartSubscription,
    startSubscriptionLoading,
    startMySubscription,
    toggleCancelSubscription,
    cancelSubscription,
    cancelTrialSubscription,
    subscriptionEnd,
    remainingDaysAfterCanceled,
    isCardExpired,
    getFinalPrice,
    alreadyMakePayment,
  }
})
